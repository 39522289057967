<!-- immex图表详情页 -->
<template>
  <div class="immexDetails" id="scroll" style="margin-top: -20px;padding-top: 20px;">
    <!-- <el-button @click="goUpperLevel" v-if="showReport" style="margin-right:10px">
      <i class="el-icon-back" style="margin-right:10px"></i>返回
    </el-button>-->
    <el-button
      @click="goUpperLevel"
      v-if="showScreen&&$route.path == '/chartIMMEXDetail'"
      style="margin-right:10px"
    >
      <i class="el-icon-back" style="margin-right:10px"></i>返回
    </el-button>
    <!-- <span v-if="showScreen">学生：{{studentDetails.fullName}}-{{studentDetails.userName}}</span> -->

    <!-- //自己来回修改的东西 -->
    <!-- <el-card>
    <span class="span-title">SPM</span>-->
    <!-- 有问题的四象图SPM -->
    <!-- 
      <el-carousel height="500px" autoplay :interval="3000">
        <el-carousel-item class="banner_carousel">
          <div style="height:500px;width:100%;overflowY: scroll;overflowX: hidden" ref="spmBox">
            <div ref="spmEcharts" :style="{width: '100%', height: '1100px'}"></div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </el-card>-->
    <el-card>
      <span class="span-title">四象限分析效果图</span>
      <div ref="immexEcharts" :style="{width: '100%', height: '500px'}"></div>
    </el-card>
    <el-card>
      <span class="span-title">策略三分析效果图</span>
      <div ref="strategyEcharts" :style="{width: '100%', height: '500px'}"></div>
    </el-card>
    <el-card>
      <span class="span-title">SPM</span>
      <!-- 有问题的四象图SPM -->
      <el-carousel height="500px" autoplay :interval="3000">
        <el-carousel-item class="banner_carousel">
          <div style="height:500px;width:100%;overflowY: scroll;overflowX: hidden" ref="spmBox">
            <div ref="spmEcharts" :style="{width: '100%', height: '1100px'}"></div>
          </div>
        </el-carousel-item>

        <el-carousel-item class="banner_carousel">
          <div style="height:500px;width:100%;overflowY: scroll;overflowX: hidden" ref="spmBox">
            <div ref="spmEcharts2" :style="{width: '100%', height: '1100px'}"></div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </el-card>
    <el-card>
      <span class="span-title">数据表</span>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="createTime" label="布置时间" width="100"></el-table-column>
        <el-table-column prop="startTime" label="开始时间" width="100"></el-table-column>
        <el-table-column prop="endTime" label="结束时间" width="100"></el-table-column>
        <el-table-column prop="caseSN" label="变式编号"></el-table-column>
        <el-table-column label="结果">
          <template slot-scope="scope">
            <span>
              一次就正确
              <br />
              ({{scope.row.correctRat}})
            </span>
          </template>
        </el-table-column>
        <el-table-column label="用时" width="90">
          <template slot-scope="scope">
            <span>{{scope.row.minTotalTime}} / {{scope.row.maxTotalTime}}</span>
          </template>
        </el-table-column>
        <el-table-column label="效率" width="80">
          <template slot-scope="scope">
            <span>{{scope.row.minEfficiency}} / {{scope.row.maxEfficinecy}}</span>
          </template>
        </el-table-column>
        <el-table-column label="效果" width="90">
          <template slot-scope="scope">
            <span>{{scope.row.minEffectiveness}} / {{scope.row.minEffectiveness}}</span>
          </template>
        </el-table-column>
        <el-table-column label="步长">
          <template slot-scope="scope">
            <span>{{scope.row.minSteps}} / {{scope.row.maxSteps}}</span>
          </template>
        </el-table-column>
        <el-table-column label="回路数">
          <template slot-scope="scope">
            <span>{{scope.row.minLoops?scope.row.minLoops:'--'}} / {{scope.row.maxLoops?scope.row.maxLoops:'--'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="interferenceRate" label="干扰率"></el-table-column>
        <el-table-column prop="irrelevantRate" label="无关率"></el-table-column>
        <el-table-column prop="correlationRate" label="相关率"></el-table-column>
        <el-table-column prop="feasible" label="可行"></el-table-column>
        <el-table-column prop="optimized" label="优化"></el-table-column>
        <el-table-column prop="stable" label="稳定"></el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :current-page="1"
        :pager-count="5"
        :page-size="2"
        @current-change="currentChange"
      ></el-pagination>
    </el-card>
  </div>
</template>

<script>
import ecStat from 'echarts-stat';
import moment from "moment";
export default {
  data () {
    return {
      chart: null,//图表实例
      strategyChart: null,//图表实例
      spmChart: null,//图表实例
      tableData: [],//数据表
      showScreen: false,//是否展示学生信息
      showReport: false,//(该字段暂时未用到)
      immexEchartsData: [],//imx数据
      studentDetails: {},//学生信息
      total: 1,//条数
      arrIndex: 0,//停留时长
      xLength: 0,//统计图X轴
      status: 0,//0  老师  1  学生
    }
  },
  props: {
    paramObj: {
      type: Object,
      default: () => { }
    }
  },
  watch: {
    paramObj (val, old) {
      if (val !== old) {
        this.getAllChart()
      }
    }
  },
  components: {},
  created () {

  },
  mounted () {
    this.getIMMXCaseRecordData(1)
    this.$nextTick(() => {
      var anchor = document.querySelector('#scroll');
      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    })
    if (this.$route.path == '/chartIMMEXDetail' && this.status == 1) {
      this.showReport = true
    }
    this.getAllChart()
    this.analysis()
  },
  methods: {
    // 解析appIds
    analysis () {
      if (this.utils.identity('IMX做题')) {
        this.status = 1
        this.showScreen = true
      } else {
        this.showScreen = true
      }
    },
    currentChange (val) {
      this.getIMMXCaseRecordData(val)
    },
    // 返回上一级
    goUpperLevel () {
      this.$router.go(-1)
    },
    immexEcharts () {
      this.chart = this.$echarts.init(this.$refs.immexEcharts)
      var option;
      option = {
        tooltip: {
          type: 'item',
          formatter: function (params) {
            return (
              "<b>" +
              params.name +
              "</b> <br />有效性: " + (params.value[0] != null ? (params.value[0] + "%") : '--') +
              "</b> <br />有效率: " + (params.value[1] != null ? (params.value[1] + "%") : '--')
            );
          },
        },
        xAxis: {
          splitLine: {
            show: true
          }
        },
        yAxis: {
          splitLine: {
            show: true
          }
        },
        series: {
          type: 'scatter',
          name: '',
          symbolSize: 15,
          itemStyle: {
            borderColor: '#555'
          },
          label: {
            show: true,
            position: 'right',
            formatter: function (params) {
              return params.name
            }
          },
          data: [],
          markLine: {
            silent: true, // 是否不响应鼠标事件
            precision: 2, // 精度
            symbol: 'none',
            lineStyle: {
              type: 'solid',
              color: 'rgb(110 112 121)'
            },
            label: {
              show: false,
            },
            data: [
              {
                name: 'y', yAxis: ''
              },
              {
                name: 'x', xAxis: ''
              }
            ]
          },
        }
      };
      let params = {
        "activeId": this.studentDetails.activeId,
        "caseId": this.studentDetails.caseId, //K12老师没有
        "caseType": 1,
        "isComplete": this.studentDetails.isComplete,
        "taskId": this.studentDetails.taskId,
        "userId": this.studentDetails.userId,
        "classId": this.studentDetails.classId
      }
      this.immexEchartsData = []
      this.$api.page.getAllEfficiency(params).then((res) => {
        if (res.data.data.userData.length > 0) {
          let arr = res.data.data.userData
          let itemColor = ''
          arr.forEach((item, i) => {
            if (item.isHighlight == 1) {
              itemColor = 'red'
            } else {
              itemColor = 'none'
            }
            this.immexEchartsData.push({
              name: item.userFullName,
              caseId: item.caseId,
              value: [item.effectiveness ? (item.effectiveness * 100).toFixed(2) : null, item.efficiency ? (item.efficiency * 100).toFixed(2) : null],
              itemStyle: {
                color: itemColor
              }
            })
          });
          option.series.markLine.data[1].xAxis = (res.data.data.avgEffectiveness * 100).toFixed(0)
          option.series.markLine.data[0].yAxis = (res.data.data.avgEfficiency * 100).toFixed(0)
          this.$set(option.series, 'data', this.immexEchartsData);
          this.chart.setOption(option);
        } else {
          this.$set(option.series, 'data', []);
          this.chart.clear()
        }
      })
    },
    strategyEcharts () {
      this.strategyChart = this.$echarts.init(this.$refs.strategyEcharts)
      var option = {
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            var relVal = params[0].name;
            for (var i = 0, l = params.length; i < l; i++) {
              var vl = params[i].value[params[i].seriesIndex + 1] != null ? params[i].value[params[i].seriesIndex + 1] + '%' : '--'
              relVal += '<br/>' + params[i].seriesName + ' : ' + vl;
            }
            return relVal;
          },
        },
        legend: {
          show: true
        },
        grid: {
          left: '3%',
          right: '5%',
          bottom: '3%',
          containLabel: true
        },
        dataset: {
          source: [
            ['大区', 1, 2],
            ['可行性', 123, 456],
            ['稳定性', 23, 56],
            ['优化性', 245, 43]
          ]
        },
        xAxis: {
          type: 'category',
          name: '任务（次）',
          nameLocation: 'end',
          boundaryGap: false,
          splitLine: {
            show: true
          }
        },
        yAxis: {
          type: 'value',
          name: '程度|%',
          axisLine: {
            show: true
          },
          axisTick: { show: true },
          splitLine: {
            show: false
          }
        },
        series: [
          {
            name: '',
            type: 'line',
            seriesLayoutBy: 'row',
            symbolSize: 8,
            symbol: 'circle',
            showSymbol: true,
          },
          {
            name: '',
            type: 'line',
            seriesLayoutBy: 'row',
            symbolSize: 8,
            symbol: 'circle',
            showSymbol: true,
          },
          {
            name: '',
            type: 'line',
            seriesLayoutBy: 'row',
            symbolSize: 8,
            symbol: 'circle',
            showSymbol: true,
          }
        ]
      };
      let params = {
        "grade": 5,
        "subject": "12",
        "caseId": this.studentDetails.caseId,
        "userId": this.studentDetails.userId,
        "classId": this.studentDetails.classId,
        "problemId": this.studentDetails.problemId
      }
      this.$api.page.getIMMXTaskStateParameters(params).then((res) => {
        if (res.data.data) {
          let arr = res.data.data
          let dataset = []
          let echartDataObj = {
            '任务': [],
            '可行性': [],
            '稳定性': [],
            '优化性': []
          };
          arr.forEach((item, i) => {
            echartDataObj['任务'].push(i);
            echartDataObj['可行性'].push((item.feasible * 100).toFixed(2));
            echartDataObj['稳定性'].push((item.stable * 100).toFixed(2));
            echartDataObj['优化性'].push((item.optimize * 100).toFixed(2));
          });
          for (var key in echartDataObj) {
            echartDataObj[key].unshift(key)
            dataset.push(echartDataObj[key])
          }
          this.$set(option.dataset, 'source', dataset);
          this.strategyChart.setOption(option);
        } else {
          this.$set(option.dataset, 'source', []);
          this.strategyChart.clear()
        }
      })

    },
    spmEcharts () {
      let colorList = []
      var option = {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            var relVal = '';
            if (params.seriesName == '辅助') {
            } else {
              relVal = params.name
              var vl = Array.isArray(params.value) ? params.value[0] : params.value
              relVal += '<br/>' + params.seriesName + ' : ' + vl;
            }
            return relVal;
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          // y: 1000,
          containLabel: true
        },
        xAxis: {
          show: false,
          type: 'value',
          splitLine: {
            show: false
          }
        },
        yAxis: {
          type: 'category',
          data: [],
          inverse: true,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            name: '辅助',
            type: 'bar',
            stack: 'total',
            itemStyle: {
              normal: {
                color: function (params) {
                  return colorList[params.dataIndex]
                }
              },
              barBorderColor: 'rgba(0,0,0,0)',
              color: 'rgba(0,0,0,0)'
            },
            data: []
          },
          {
            name: '停留时间',
            type: 'bar',
            stack: 'total',
            itemStyle: {
              normal: {
                //选择的颜色
                color: '#E88308',
              }
            },
            barWidth: 20,
            data: [],
            showBackground: true,
            backgroundStyle: {
              borderWidth: 1,
              borderColor: '#eee',
              color: '#fff',
            }
          }
        ]
      };
      let params = {
        "activeId": this.studentDetails.activeId,
        "caseId": this.studentDetails.caseId, //K12老师没有
        "caseType": 1,
        "isComplete": this.studentDetails.isComplete,
        "taskId": this.studentDetails.taskId,
        "userId": this.studentDetails.userId,
      }
      //网络请求拿到所有数据并且把数据绑定到data渲染出来
      this.$api.page.getUserImmxPathData(params).then((res) => {
        if (res.data.data.userPathRecord) {
          //arr数据
          let arr = res.data.data.nodes
          arr = arr.filter((item) => {
            return item.optionSN !== -1
          })
          let arr1 = res.data.data.userPathRecord
          let seriesDate = [];
          arr.forEach((item, i) => {
            seriesDate.push({
              optionSN: item.optionSN,
              nodeName: item.nodeName
            })
            option.yAxis.data.push(item.nodeName)
          });
          option.yAxis.data.push('错误', '正确')
          if (Object.keys(arr1).length > 0) {
            let sz = {
              sn: arr1['userPathArray'].split(','),
              time: arr1['spendTimeArray'].split(',')
            }
            let timeArr = []
            for (let i = 0; i < seriesDate.length; i++) {
              let time = ''
              for (let j = 0; j < sz.sn.length; j++) {
                if (seriesDate[i].optionSN == sz.sn[j]) {
                  time = sz.time[j]
                  break;
                }
              }
              timeArr.push(time)
            }
            let userChoce = res.data.data.isCorrect //用户是否答对
            let correctTime = timeArr[timeArr.length - 1]
            timeArr[timeArr.length - 1] = ''
            timeArr.push('', '')
            userChoce == 1 ? timeArr[timeArr.length - 1] = correctTime : timeArr[timeArr.length - 2] = correctTime

            let s1 = [0]
            for (var i = 0; i < timeArr.length; i++) {
              s1.push(Number(s1[i]) + Number(timeArr[i] !== '' ? timeArr[i] : 0))
            }
            // -----------------------
            // 配置颜色
            timeArr.forEach((item, index) => {
              if (index == timeArr.length - 3 || index == timeArr.length - 1) {
                colorList.push('#b4eec7')
              } else if (index == timeArr.length - 2) {
                colorList.push('#ffc7bf')
              } else {
                colorList.push('#fff')
              }
            })
            // -----------------------
            let arr = []
            timeArr.forEach((item, index) => {
              if (item !== '') {
                arr.push(s1[index])
              } else {
                arr.push(Number(s1[s1.length - 1]) - Number(item))
              }
            })
            //当前问题时间等等
            s1 = arr
            this.$set(option.xAxis, 'max', Number(s1[s1.length - 1]));
            this.$set(option.series[0], 'data', s1);
            //图表数据添加进去
            this.$set(option.series[1], 'data', timeArr);

            this.$nextTick(() => {
              let a = this.$refs.spmBox.offsetWidth + 'px'
              this.spmChart = this.$echarts.init(this.$refs.spmEcharts)
              this.spmChart._dom.style.width = a;
              this.spmChart.setOption(option);
            })
          }
        } else {
          this.spmChart = this.$echarts.init(this.$refs.spmEcharts)
          this.$set(option.series[0], 'data', []);
          this.$set(option.series[1], 'data', []);
          this.strategyChart.clear()
        }
      })
    },
    spmEcharts2 () {
      let colorList = []
      var option = {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            var relVal = '';
            if (params.seriesName == '辅助') {
            } else {
              relVal = params.name
              var vl = Array.isArray(params.value) ? params.value[0] : params.value
              relVal += '<br/>' + params.seriesName + ' : ' + vl;
            }
            return relVal;
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          show: false,
          type: 'value',
          splitLine: {
            show: false
          }
        },
        yAxis: {
          type: 'category',
          data: [],
          inverse: true,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            name: '辅助',
            type: 'bar',
            stack: 'total',
            itemStyle: {
              normal: {
                color: function (params) {
                  return colorList[params.dataIndex]
                }
              },
              barBorderColor: 'rgba(0,0,0,0)',
              color: 'rgba(0,0,0,0)'
            },
            data: []
          },
          {
            name: '停留时间',
            type: 'bar',
            stack: 'total',
            itemStyle: {
              normal: {
                color: '#409EFF',
              }
            },
            barWidth: 20,
            data: [],
            showBackground: true,
            backgroundStyle: {
              borderWidth: 1,
              borderColor: '#eee',
              color: '#fff',
            }
          },
          {
            name: '停留时长',
            type: 'line',
            connectNulls: true,
            data: []
          }
        ]
      };
      let params = {
        "activeId": this.studentDetails.activeId,
        "caseId": this.studentDetails.caseId, //K12老师没有
        "caseType": 1,
        "isComplete": this.studentDetails.isComplete,
        "taskId": this.studentDetails.taskId,
        "userId": this.studentDetails.userId,
      }
      this.$api.page.getUserImmxPathData(params).then((res) => {
        if (res.data.data.userPathRecord) {
          let arr = res.data.data.nodes
          arr = arr.filter((item) => {
            return item.optionSN !== -1
          })
          let arr1 = res.data.data.userPathRecord
          let seriesDate = [];
          arr.forEach((item, i) => {
            seriesDate.push({
              optionSN: item.optionSN,
              nodeName: item.nodeName
            })
            option.yAxis.data.push(item.nodeName)
          });
          option.yAxis.data.push('错误', '正确')

          if (Object.keys(arr1).length > 0) {
            let sz = {
              sn: arr1['userPathArray'].split(','),
              time: arr1['spendTimeArray'].split(',')
            }
            let timeArr = []
            let lineArr = []
            let arr2 = []
            seriesDate.map((item, index) => {
              let time = ''
              sz.sn.map((itm, inx) => {
                if (item.optionSN == itm) {
                  this.$set(arr2, inx, item)
                }
              })
            })
            let arr3 = []
            for (let i = 0; i < arr2.length; i++) {
              let time = ''
              for (let j = 0; j < sz.sn.length; j++) {
                if (arr2[i].optionSN == sz.sn[j]) {
                  time = sz.time[j]
                  arr3.push([sz.time[j], arr2[i].nodeName])
                  lineArr.push([time, arr2[i].nodeName])
                  break;
                }

              }
            }
            arr3.map((item, index) => {
              arr3.map((itm, inx) => {
                if (item[1] == itm[1] && index !== inx) {
                  item[0] = Number(item[0]) + Number(itm[0])
                  this.$delete(arr3, inx)
                }
              })
            })
            for (let i = 0; i < seriesDate.length; i++) {
              let time = ''
              for (let j = 0; j < arr3.length; j++) {
                if (seriesDate[i].nodeName == arr3[j][1]) {
                  time = arr3[j][0]
                  break;
                }
              }
              timeArr.push(time)
            }
            let userChoce = res.data.data.isCorrect //用户是否答对
            let correctTime = timeArr[timeArr.length - 1]
            timeArr[timeArr.length - 1] = ''
            lineArr.pop()
            timeArr.push('', '')
            lineArr.push(['', '错误'], ['', '正确'])
            userChoce == 1 ? timeArr[timeArr.length - 1] = correctTime : timeArr[timeArr.length - 2] = correctTime
            userChoce == 1 ? lineArr[lineArr.length - 1] = [correctTime, '正确'] : lineArr[lineArr.length - 2] = [correctTime, '错误']
            let s1 = [0]
            for (var i = 0; i < timeArr.length; i++) {
              s1.push(Number(s1[i]) + Number(timeArr[i] !== '' ? timeArr[i] : 0))
            }
            // ------------
            // 计算停留时长(折线图)
            lineArr.forEach((item, index) => {
              if (item[0] == '') {
                this.arrIndex = index
              }
              if (index !== 0) {
                item[0] = Number(item[0]) + Number(lineArr[index - 1][0])
              }
            })
            lineArr[this.arrIndex][0] = ''
            // ------------
            // 颜色
            timeArr.forEach((item, index) => {
              if (index == timeArr.length - 3 || index == timeArr.length - 1) {
                colorList.push('#b4eec7')
              } else if (index == timeArr.length - 2) {
                colorList.push('#ffc7bf')
              } else {
                colorList.push('#fff')
              }
            })
            // ------------
            if (lineArr[lineArr.length - 2] !== '') {
              this.xLength = lineArr[lineArr.length - 2][0]
            } else if (lineArr[lineArr.length - 1] !== '') {
              this.xLength = lineArr[lineArr.length - 2][0]
            }
            let arr = []
            timeArr.forEach(item => {
              if (item !== '') {
                arr.push(this.xLength - Number(item))
              } else {
                arr.push(this.xLength)
              }
            })
            s1 = arr
            this.$set(option.xAxis, 'max', this.xLength);
            this.$set(option.series[2], 'data', lineArr);
            this.$set(option.series[0], 'data', s1);
            this.$set(option.series[1], 'data', timeArr);
            this.$nextTick(() => {
              let a = this.$refs.spmBox.offsetWidth + 'px'
              this.spmChart = this.$echarts.init(this.$refs.spmEcharts2)
              this.spmChart._dom.style.width = a;
              this.spmChart.setOption(option);
            })
          }

        } else {
          this.spmChart = this.$echarts.init(this.$refs.spmEcharts)
          this.$set(option.series[0], 'data', []);
          this.$set(option.series[1], 'data', []);
          this.$set(option.series[2], 'data', []);
          this.strategyChart.clear()
        }
      })
    },
    getIMMXCaseRecordData (pageNum) {
      let params = {
        "caseID": this.studentDetails.caseId,
        "classId": this.studentDetails.classId,
        "pageNum": pageNum,
        "pageSize": 2,
        "problemId": this.studentDetails.problemid
      }
      this.tableData = [];
      this.$api.page.getIMMXCaseRecordData(params).then((res) => {
        if (res.data.data) {
          if (res.data.data.list.length > 0) {
            let list = res.data.data.list;
            this.total = res.data.data.total;
            list.forEach((item, i) => {
              item.createTime = moment(item.createTime).format("YYYY/MM/DD HH:MM:SS");
              item.startTime = moment(item.startTime).format("YYYY/MM/DD HH:MM:SS");
              item.endTime = item.endTime ? moment(item.endTime).format("YYYY/MM/DD HH:MM:SS") : '--'
              item.correctRat = (item.correctRat * 100).toFixed(2) + '%'
              item.minEfficiency = (item.minEfficiency * 100).toFixed(2) + '%'
              item.maxEfficinecy = (item.maxEfficinecy * 100).toFixed(2) + '%'
              item.interferenceRate = (item.interferenceRate * 100).toFixed(2) + '%'
              item.irrelevantRate = (item.irrelevantRate * 100).toFixed(2) + '%'
              item.correlationRate = (item.correlationRate * 100).toFixed(2) + '%'
              item.feasible = (item.feasible * 100).toFixed(2) + '%'
              item.stable = (item.stable * 100).toFixed(2) + '%'
              item.optimized = (item.optimized * 100).toFixed(2) + '%'
              item.minEffectiveness = item.minEffectiveness ? (item.minEffectiveness * 100).toFixed(2) + '%' : '--'
              item.maxEffectiveness = item.maxEffectiveness ? (item.maxEffectiveness * 100).toFixed(2) + '%' : '--'
              item.minTotalTime = moment.utc(item.minTotalTime * 1000).format('HH:mm:ss');
              item.maxTotalTime = moment.utc(item.maxTotalTime * 1000).format('HH:mm:ss');
            });
            this.tableData = list;
          }
        }
      })
    },
    getAllChart () {
      if (this.$route.params.paramObj) {
        this.studentDetails = this.$route.params.paramObj;
      } else {
        this.studentDetails = this.paramObj;
      }
      this.immexEcharts()
      this.strategyEcharts()
      this.spmEcharts()
      this.spmEcharts2()
      this.getIMMXCaseRecordData(1)
    }
  }
}

</script>
<style lang='less' scoped>
@import '../../style/mouse.less';
.el-card {
  margin-top: 10px;
}
.span-title {
  font-weight: 700;
  font-size: 18px;
  border-left: 5px solid #409eff;
  padding-left: 10px;
}
.el-pagination {
  display: inline-block;
  position: relative;
  left: 50%;
  top: 10px;
  transform: translate(-50%);
  margin-bottom: 30px;
}
.immexDetails/deep/table th,
.immexDetails/deep/table td {
  text-align: center;
}
</style>